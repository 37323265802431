var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-container', {
    staticClass: "d-flex justify-center align-center",
    staticStyle: {
      "min-height": "100vh"
    }
  }, [_c('v-snackbar', {
    attrs: {
      "color": "success",
      "timeout": "2000",
      "bottom": ""
    },
    model: {
      value: _vm.snackbar,
      callback: function callback($$v) {
        _vm.snackbar = $$v;
      },
      expression: "snackbar"
    }
  }, [_vm._v(" " + _vm._s(_vm.snackbarText) + " ")]), _c('v-card', {
    staticClass: "radius-10 white mx-auto",
    class: {
      'pa-7': _vm.$vuetify.breakpoint.mdAndUp,
      'py-7 px-4': _vm.$vuetify.breakpoint.mdAndDown
    },
    attrs: {
      "width": "800",
      "flat": ""
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('img', {
    staticClass: "mb-7",
    attrs: {
      "alt": "Logo",
      "src": "/media/logos/logo.png"
    }
  })]), !_vm.iOSSafari ? _c('v-card', {
    staticClass: "radius-10 white--text py-3 px-4",
    attrs: {
      "flat": "",
      "color": "error"
    }
  }, [_c('div', {
    staticClass: "white--text"
  }, [_vm._v(" You need to open this page from your iPhone or iPad Safari browser ")])]) : _vm._e(), !_vm.iOSSafari ? _c('v-card', {
    staticClass: "pa-3 mt-2"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "py-4"
  }, [_vm._v(" If you're currently accessing this page using an iPad or iPhone, you should modify this setting. ")]), _c('v-img', {
    attrs: {
      "s": "",
      "width": "100%",
      "contain": "",
      "src": "/media/instruction/enable_mobile_mode.jpg"
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols-12": ""
    }
  }, [_c('b', {
    staticClass: "primary--text cursor-pointer",
    on: {
      "click": _vm.openUrl
    }
  }, [_vm._v("Click here")]), _vm._v(" for more information ")])], 1)], 1) : _vm._e(), _c('br'), !_vm.iOSSafari ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" You can copy this URL and paste it in your iOS "), _c('b', [_vm._v("Safari Browser")]), _c('a', {
    staticClass: "mt-5 primary--text d-block",
    attrs: {
      "href": _vm.theURL,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.theURL) + " ")]), _c('v-btn', {
    directives: [{
      name: "clipboard",
      rawName: "v-clipboard",
      value: function value() {
        return _vm.theURL;
      },
      expression: "() => theURL"
    }, {
      name: "clipboard",
      rawName: "v-clipboard:success",
      value: _vm.alertCopy,
      expression: "alertCopy",
      arg: "success"
    }],
    staticClass: "main large primary mx-auto my-6"
  }, [_vm._v("Copy URL")]), _c('p', [_vm._v("Or")]), _c('p', [_vm._v(" Scan this QR code from your iPhone or iPad to access it from "), _c('b', [_vm._v("Safari browser")])]), _c('div', {
    staticClass: "mt-7"
  }, [_c('img', {
    attrs: {
      "src": "/media/svg/qr-code.png",
      "height": "200"
    }
  })])], 1) : _vm._e(), _vm.iOSSafari && _vm.isEmpty(_vm.$route.query.udid) ? _c('div', {
    staticClass: "text-center"
  }, [_c('h3', [_vm._v("Get your Device ID (UDID)")]), _c('br'), _c('p', [_vm._v(" Tap the download button to install the temporary profile and iOS will take care of the rest. ")]), _c('p', [_vm._v("After downloading the profile you will need to go to")]), _c('div', {
    staticClass: "bold-text"
  }, [_vm._v("Settings -> Profile Downloaded")]), _c('a', {
    staticClass: "px-6 py-4 radius-10 bold-text text-capitalize primary mx-auto white--text my-5 d-inline-block",
    attrs: {
      "href": "https://testapp.io/config/config.mobileconfig"
    },
    on: {
      "click": _vm.openSettingPopup
    }
  }, [_vm._v("Download")])]) : _vm._e(), _vm.iOSSafari && !_vm.isEmpty(_vm.$route.query.udid) ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" Your Device ID (UDID) "), _c('p', [_vm._v(_vm._s(_vm.$route.query.udid))]), _c('p', [_vm._v(" Tap on the continue button to add this Device ID (UDID) to your account ")]), _c('div'), _c('a', {
    staticClass: "px-6 py-4 radius-10 bold-text primary mx-auto white--text d-inline-block",
    attrs: {
      "href": "testappio://udid/".concat(_vm.$route.query.udid)
    }
  }, [_vm._v("Continue")])]) : _vm._e()], 1), _c('Modal', {
    scopedSlots: _vm._u([{
      key: "message",
      fn: function fn() {
        return [_c('v-card-title', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" Open Settings ")]), _c('v-card-text', {
          staticClass: "message"
        }, [_vm._v(" To complete the setup, open settings and navigate to "), _c('b', [_vm._v("Profile Downloaded")])]), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
          staticClass: "main",
          on: {
            "click": function click($event) {
              _vm.openSettingModal = false;
            }
          }
        }, [_vm._v("cancel")]), _c('v-btn', {
          staticClass: "main primary",
          attrs: {
            "tag": "a",
            "href": "App-prefs://prefs:root=General&path=ManagedConfigurationList"
          }
        }, [_vm._v(" Open Settings ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.openSettingModal,
      callback: function callback($$v) {
        _vm.openSettingModal = $$v;
      },
      expression: "openSettingModal"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }