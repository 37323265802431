<template>
  <div>
    <v-container
      class="d-flex justify-center align-center"
      style="min-height: 100vh"
    >
      <v-snackbar v-model="snackbar" color="success" timeout="2000" bottom>
        {{ snackbarText }}
      </v-snackbar>
      <v-card
        class="radius-10 white mx-auto"
        :class="{
          'pa-7': $vuetify.breakpoint.mdAndUp,
          'py-7 px-4': $vuetify.breakpoint.mdAndDown,
        }"
        width="800"
        flat
      >
        <div class="text-center">
          <img alt="Logo" src="/media/logos/logo.png" class="mb-7" />
        </div>

        <!-- if there is udid show secces msg else show err msg -->
        <v-card
          v-if="!iOSSafari"
          class="radius-10 white--text py-3 px-4"
          flat
          color="error"
        >
          <div class="white--text">
            You need to open this page from your iPhone or iPad Safari browser
          </div>
        </v-card>
        <v-card v-if="!iOSSafari" class="pa-3 mt-2">
          <v-row>
            <v-col cols="12">
              <h3 class="py-4">
                If you're currently accessing this page using an iPad or iPhone,
                you should modify this setting.
              </h3>
              <v-img
                s
                width="100%"
                contain
                src="/media/instruction/enable_mobile_mode.jpg"
              ></v-img>
            </v-col>
            <v-col class="text-center" cols-12>
              <b class="primary--text cursor-pointer" @click="openUrl"
                >Click here</b
              >
              for more information
            </v-col>
          </v-row>
        </v-card>
        <!-- <v-card
          v-else-if="iOSSafari && !isEmpty($route.query.udid)"
          class="radius-10 white--text py-3 px-4"
          flat
          color="success"
        >
          Successfully retrieved your Device ID (UDID) 🌟
        </v-card> -->
        <br />

        <!-- if no query udid and not safari -->
        <div v-if="!iOSSafari" class="text-center">
          You can copy this URL and paste it in your iOS <b>Safari Browser</b>
          <a :href="theURL" target="_blank" class="mt-5 primary--text d-block">
            {{ theURL }}
          </a>
          <v-btn
            class="main large primary mx-auto my-6"
            v-clipboard="() => theURL"
            v-clipboard:success="alertCopy"
            >Copy URL</v-btn
          >
          <p>Or</p>
          <p>
            Scan this QR code from your iPhone or iPad to access it from
            <b>Safari browser</b>
          </p>
          <div class="mt-7">
            <img src="/media/svg/qr-code.png" height="200" />
          </div>
        </div>

        <!-- if no query udid and safari -->
        <div v-if="iOSSafari && isEmpty($route.query.udid)" class="text-center">
          <h3>Get your Device ID (UDID)</h3>
          <br />

          <p>
            Tap the download button to install the temporary profile and iOS
            will take care of the rest.
          </p>
          <p>After downloading the profile you will need to go to</p>
          <div class="bold-text">Settings -> Profile Downloaded</div>
          <a
            class="px-6 py-4 radius-10 bold-text text-capitalize primary mx-auto white--text my-5 d-inline-block"
            @click="openSettingPopup"
            href="https://testapp.io/config/config.mobileconfig"
            >Download</a
          >
        </div>

        <!-- if query udid and safari -->
        <div
          v-if="iOSSafari && !isEmpty($route.query.udid)"
          class="text-center"
        >
          Your Device ID (UDID)
          <p>{{ $route.query.udid }}</p>
          <p>
            Tap on the continue button to add this Device ID (UDID) to your
            account
          </p>
          <div></div>
          <a
            class="px-6 py-4 radius-10 bold-text primary mx-auto white--text d-inline-block"
            :href="`testappio://udid/${$route.query.udid}`"
            >Continue</a
          >
        </div>
      </v-card>
      <!-- <Modal
        v-model="openSettingModal"
        title="Open Settings"
        message="To complete the setup, open settings and navigate to Profile Downloaded"
        @onConfirm="openSettings"
        is-mobile
        confirmText="Open Settings"
        cancel-text="Cancel"
        @onCancel="openSettingModal = false"
      ></Modal> -->

      <Modal v-model="openSettingModal">
        <template #message>
          <v-card-title class="font-weight-bold"> Open Settings </v-card-title>
          <v-card-text class="message">
            To complete the setup, open settings and navigate to
            <b>Profile Downloaded</b>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="main" @click="openSettingModal = false">cancel</v-btn>
            <v-btn
              tag="a"
              href="App-prefs://prefs:root=General&path=ManagedConfigurationList"
              class="main primary"
            >
              Open Settings
            </v-btn>
          </v-card-actions>
        </template>
      </Modal>
    </v-container>
  </div>
</template>

<script>
import { isEmpty } from "@/core/services/helper.service";
export default {
  data() {
    return {
      isEmpty,
      iOSSafari: false,
      copyAlert: false,
      openSettingModal: false,
      theURL: "https://testapp.io/udid",
      snackbar: false,
      snackbarText: "",
    };
  },
  methods: {
    openSettingPopup() {
      setTimeout(() => {
        this.openSettingModal = true;
      }, 2000);
    },
    openUrl() {
      window.open(
        "https://help.testapp.io/faq/enable-mobile-mode-on-safari/",
        "_blank"
      );
    },
    openSettings() {
      window?.location?.replace(
        "App-prefs://prefs:root=General&path=ManagedConfigurationList"
      );
    },
    alertCopy() {
      //   alert("the URL copied successfully");
      //   this.notifyUserMessage("the URL copied successfully");
      this.snackbarText = "Copied successfully";
      this.snackbar = true;
    },
  },
  created() {
    //Hide intercom chat
    if (window && window.Intercom) {
      window.Intercom("hide");
    }
  },
  beforeMount() {
    var ua = window.navigator.userAgent;
    var iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    var webkit = !!ua.match(/WebKit/i);
    var iOSSafari = iOS && webkit && !ua.match(/CriOS/i);

    if (iOSSafari) {
      this.iOSSafari = true;
    }
  },
};
</script>

<style lang="scss" scoped></style>
